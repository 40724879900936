<template>
  <RFModal :modalId="modalId" title="Add Infos" :loading="loading"
  @send="save" has-cancel ok-label="Save" cancel-label="Cancel">
    <div class="add-info" @click="addInfo">
      <FontAwesomeIcon :icon="['fas', 'plus-circle']" color="#5CB86C" /> {{$t('Add Info')}}
    </div>
    <AdminInfo class="mb-4" v-for="(info, index) in infos" :key="index" :index="index" :information="info" @delete="deleteInfo"/>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import AdminInfo from '@/components/admin/AdminInfo'

import { buildingService } from '@/services/admin'
import { mapState } from 'vuex'

export default {
  name: 'AddInfoModal',
  components: {
    AdminInfo,
    RFModal,
  },
  data() {
    return {
      modalId: 'add-infos-modal',
      infos: [],
      loading: false,
    }
  },
  beforeMount() {
    this.addInfo()
  },
  computed: {
    ...mapState({
      building: state => state.adminBuildingStore.building,
    }),
  },
  methods: {
    addInfo() {
      this.infos.push({ title: null, description: null, file: null })
    },
    deleteInfo(index) {
      if (this.infos.length === 1) {
        return false
      }

      this.infos.splice(index, 1)
    },
    async save() {
      this.loading = true

      const infos = await Promise.all(
        this.infos.map(async (info) => {
          const { title, description, file } = info
          const form = new FormData()

          form.append('title', JSON.stringify(title))
          form.append('description', JSON.stringify(description))
          form.append('file', file)

          const { data: newInfo } = await buildingService.addInfo(this.building.id, form)

          return newInfo
        })
      )

      this.$store.commit('adminBuildingStore/setInfos', [...this.building.infos, ...infos])

      this.loading = false

      this.$emit('update', this.$store.state.adminBuildingStore.building.infos)
      this.infos = []
      this.$toasted.success(this.$i18n.t(`Infos correctly added!`))
      this.$root.$emit('bv::hide::modal', this.modalId)
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  @include font-style($montserrat, 'semibold', $font-30);
  text-transform: uppercase;
  margin: 0;
}
.add-info {
  @include font-style($montserrat, 'semibold', $font-16);
  text-align: right;
  color: $success;
  cursor: pointer;
  margin-bottom: 30px;
}
</style>
