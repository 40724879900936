<template>
  <RFModal :modalId="modalId" title="Add Images" has-cancel :loading="loading" @send="save" @cancel="cancelImageUpload()"
    ok-label="Save" cancel-label="Cancel">
    <AdminImageList :selected-image-index="selectedImageIndex" :selected-planimetry-index="selectedPlanimetryIndex" @planimetry="selectedPlanimetryIndex = $event" :images="images" @images="images = $event"
      @select="selectedImageIndex = $event" :limit="limit" :is-accommodation="isAccommodation" :multiple="multiple" :accept-videos="acceptVideos" :validate-file-size-limit="validateFileSizeLimit"/>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import AdminImageList from '@/components/admin/AdminImageList'

export default {
  name: 'AddImagesModal',
  components: {
    RFModal,
    AdminImageList,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    roomType: {
      type: Object,
      default: null,
    },
    limit: {
      type: [Number, Boolean],
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    isAccommodation: {
      type: Boolean,
      default: true,
    },
    acceptVideos:{
      type: Boolean,
      default: false
    },
    validateFileSizeLimit:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalId: 'add-images-modal',
      images: [],
      selectedImageIndex: null,
      selectedPlanimetryIndex: null,
      loading: false,
    }
  },
  beforeMount() {
    this.modalId = `${this.modalId}${this.id ? `-${this.id}` : ''}`
  },
  methods: {
    async save() {
      if (!this.images.length) {
        this.$toasted.error(this.$i18n.t(`There are no images.`))
        return false
      }

      if (this.roomType) {
        this.$emit('update-room-type', this.images, this.selectedImageIndex)
      } else {
        if (this.selectedImageIndex != null || this.selectedPlanimetryIndex != null) {
          this.$emit('update', this.images, this.selectedImageIndex, this.selectedPlanimetryIndex)
        } else {
          this.$emit('update', this.images)
        }
      }

      this.$root.$emit('bv::hide::modal', this.modalId)
      this.selectedImageIndex = null

      this.images = []
    },
    cancelImageUpload(){
      this.selectedImageIndex = null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
