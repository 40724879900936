<template>
  <div class="edit-building">
    <h1 class="title">{{$t('Edit Building')}}</h1>
    <ValidationObserver ref="validator">
      <form class="edit-building-form" @submit.prevent>
        <b-row>
          <b-col cols="4">
            <RFValidation rules="required" name="Name">
              <RFInput label="Name" v-model="building.buildingName"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Building Code">
              <RFInput label="Building Code" v-model="building.buildingCode"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Type">
              <RFSelect top-label="Type" @input="building.resTypeId = $event" :value="building.resType.name" :list="buildingTypeList"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation rules="required" name="Description">
              <RFInput label="Description" v-model="building.description"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="Building Characteristics" v-model="editedCharacteristics" :occuped-list="building.characteristics" :list="characteristics" property="name"/>
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Address')}}</h4>

        <b-row>
          <b-col>
            <RFValidation rules="required" name="Country">
              <RFInput label="Country" v-model="building.addressCountry"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="City">
              <RFInput label="City" v-model="building.addressCity"/>
            </RFValidation>
          </b-col>
                    <b-col>
            <RFValidation rules="required" name="Zip Code">
              <RFInput label="Zip Code" v-model="building.addressZipCode" is-numeric/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation rules="required" name="Street">
              <RFInput label="Street" v-model="building.addressStreet"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Number">
              <RFInput label="Number" v-model="building.addressNumber" is-numeric/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFMultipleInput label="Nearest Universities" v-model="building.nearestUni" :list="building.nearestUni" />
          </b-col>
        </b-row>
        <b-row class="justify-content-end mb-5">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB86C" v-if="!loading" @on-click="save"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>

        <b-row class="building-section">
          <b-col class="mb-4">
            <div class="header d-flex justify-content-between">
              <div class="section-title">{{$t('Building')}} {{$t('Primary Image')}}</div>
              <div class="add-info-label cursor-pointer" @click="openImagesModal(null)">
                <FontAwesomeIcon :icon="['fas', 'plus-circle']" color="#5CB86C" /> {{$t('Building')}} {{$t('Primary Image')}}
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <AdminImageList :images="building.images" :selected-image-index="selectedImageIndex"
              @select="selectedImageIndex = $event" @delete="deleteImage" :editable="false" min-height="300px"/>
          </b-col>
          <b-col class="my-4">
            <p class="admin-aclaration">{{$t(`Images and videos max size is ${fileSizeLimit} MB (Max size recommended: ${fileSizeLimitRecommended} MB)`)}}</p>
          </b-col>
        </b-row>
        <b-row class="building-section">
          <b-col class="mb-4">
            <div class="header d-flex justify-content-between">
              <div class="section-title">{{$t('Building Infos')}}</div>
              <div class="add-info-label cursor-pointer" @click="openInfosModal">
                <FontAwesomeIcon :icon="['fas', 'plus-circle']" color="#5CB86C" /> {{$t('Add Info')}}
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="mb-4">
            <AdminInfoList class="mb-4" :infos="building.infos" @delete="updateInfos" :editable="false"/>
          </b-col>
        </b-row>
      </form>
      <b-row class="building-section" v-for="(type, index) in accommodationTypes" :key="`type-${index}`">
        <template v-if="isAvailableRoomType(type.name)">
          <b-col class="mb-5">
            <div class="header d-flex justify-content-between">
              <div class="section-title">{{$t(`${type.name}`) | replaceUnderscore}}</div>
              <div class="add-info-label cursor-pointer" @click="openImagesModal(type)">
                <FontAwesomeIcon :icon="['fas', 'plus-circle']" color="#5CB86C"/> {{$t('Add Images')}}
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <AdminImageList v-if="building.roomTypeImagesMap[type.name] && building.roomTypeImagesMap[type.name].length"
            :images="building.roomTypeImagesMap[type.name]" :editable="false" :room-type="type" @images="reorderImages($event, type.name)"
            @delete-room-type="deleteRoomTypeImages" min-height="300px" @edit-room-type="editRoomTypeImages"/>
          </b-col>
          <b-col class="my-4">
            <p class="admin-aclaration">{{$t(`Images and videos max size is ${fileSizeLimit} MB (Max size recommended: ${fileSizeLimitRecommended} MB)`)}}</p>
          </b-col>
        </template>
      </b-row>
    </ValidationObserver>
    <AddImagesModal @update="updateImages"  :limit="1" :editable="false" :multiple="false" :accept-videos="true" :validate-file-size-limit="true"/>
    <AddImagesModal id="room-type" :room-type="selectedRoomType" @update-room-type="updateRoomTypeImages"
    @delete="deleteRoomTypeImages" :accept-videos="true" :validate-file-size-limit="true"/>
    <AddInfoModal @update="updateInfos"/>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFSelect from '@/components/forms/RFSelect'
import RFSelectItems from '@/components/forms/RFSelectItems'
import RFLoader from '@/components/forms/RFLoader'
import RFValidation from '@/components/forms/RFValidation'
import RFMultipleInputAutocomplete from '@/components/forms/RFMultipleInputAutocomplete'
import RFMultipleInput from '@/components/forms/RFMultipleInput'

import AdminImageList from '@/components/admin/AdminImageList'
import AdminInfoList from '@/components/admin/AdminInfoList'

import AddImagesModal from '@/components/admin/AddImagesModal'
import AddInfoModal from '@/components/admin/AddInfoModal'

import {extractIds} from '@/helpers/functions'
import {adminService, buildingService, buildingTypeService} from '@/services/admin'
import {mapState} from 'vuex'
import {store} from '@/stores'

export default {
  name: 'AdminEditBuilding',
  components: {
    RFInput,
    RFButton,
    RFSelect,
    RFSelectItems,
    RFLoader,
    RFValidation,
    RFMultipleInputAutocomplete,
    RFMultipleInput,

    AdminImageList,
    AdminInfoList,

    AddImagesModal,
    AddInfoModal,
  },
  beforeRouteEnter(to, from, next) {
    if(!store.state.adminBuildingStore.building) {
      next({ name: 'building-list' })
    } else {
      next()
    }
  },
  data() {
    return {
      building: null,
      selectedRoomType: null,
      editedCharacteristics: [],
      loading: false,
      selectedImageIndex: null,
      buildingTypeList: [],
      fileSizeLimit: 100, //In MB
      fileSizeLimitRecommended: 10, //In MB
    }
  },
  computed: {
    ...mapState({
      storedBuilding: state => state.adminBuildingStore.building,
      characteristics: state => state.adminCharacteristicStore.characteristics,
      accommodationTypes: state => state.accommodationTypeStore.accommodationTypes,
      //buildingTypes: state => state.adminBuildingTypeStore.buildingTypes,
    })
  },
  beforeMount() {
    this.building = { ...this.storedBuilding }
    this.selectedImageIndex = this.building.images && this.building.images.length
                              ? this.building.images.findIndex(image => image.isPrimary)
                              : 0
    //this.buildingTypeList = this.buildingTypes.map(item => ({ value: item.id, label: item.name }))
    this.accommodationTypes.forEach(type => {
      if (!this.building.roomTypeImagesMap[type.name]) {
        this.building.roomTypeImagesMap[type.name].sort((a, b) => a.orderIndex - b.orderIndex)
      }
    })
    this.updateBuildingTypes()
  },
  watch: {
    editedCharacteristics: {
      deep: true,
      handler() {
        this.building.characteristicIds = extractIds(this.editedCharacteristics)
      }
    }
  },
  methods: {
    async updateBuildingTypes() {
      this.buildingTypes = await buildingTypeService.getBuildingTypes()
      this.buildingTypeList = this.buildingTypes.map(type => ({value: type.id, label: type.name}))
    },
    openImagesModal(roomType = null) {
      if (roomType) {
        this.selectedRoomType = roomType
        this.$root.$emit('bv::show::modal', 'add-images-modal-room-type')
      } else {
        this.$root.$emit('bv::show::modal', 'add-images-modal')
      }

    },
    openInfosModal() {
      this.$root.$emit('bv::show::modal', 'add-infos-modal')
    },
    isAvailableRoomType(name) {
      return this.building.availableRoomTypes.find(roomType => roomType.name === name)
    },
    reorderImages(images, type){
      this.building.roomTypeImagesMap[type] = images
      this.building.roomTypeImagesMap[type].forEach((file, index) => {
        file.orderIndex = index;
      });
    },
    async updateRoomTypeImages(images, selectedImageIndex) {
      if (!this.building.roomTypeImagesMap[this.selectedRoomType.name]) {
        this.building.roomTypeImagesMap[this.selectedRoomType.name] = []
      }

      let count = this.building.roomTypeImagesMap[this.selectedRoomType.name].length
      images.forEach((file, index) => {
        file.orderIndex = count + index;
      });
      const newImages = await Promise.all(
        images.map(async (image, index) => {
          const form = new FormData()

          form.append('file', image)
          form.append('isPrimary', selectedImageIndex === index)
          form.append('typeId', this.selectedRoomType.id)
          form.append('orderIndex', image.orderIndex)

          const { data: newImage } = await buildingService.addResource(this.building.id, form)

          return newImage
        })
      )
      this.building.roomTypeImagesMap[this.selectedRoomType.name] = await buildingService.getBuildingRoomTypeImages(this.building.id, this.selectedRoomType.id);
//      this.building.roomTypeImagesMap[this.selectedRoomType.name].push(...newImages)
      this.$toasted.success(this.$i18n.t(`Room type images successfully edited!`))
      this.$forceUpdate()
    },
    async editRoomTypeImages(roomTypeName, idImage, roomTypeId) {
      await adminService.editImage(idImage)
      this.building.roomTypeImagesMap[roomTypeName] = await buildingService.getBuildingRoomTypeImages(this.building.id, roomTypeId);
      this.$forceUpdate()
    },
    async deleteRoomTypeImages(roomTypeName, idImage, roomTypeId) {
      await adminService.deleteImage(idImage)
      this.building.roomTypeImagesMap[roomTypeName] = await buildingService.getBuildingRoomTypeImages(this.building.id, roomTypeId);
      this.$forceUpdate()
    },
    async deleteImage(id) {
      await adminService.deleteImage(id)

      const index = this.building.images.findIndex(image => image.id === id)
      this.building.images.splice(index, 1)
      this.$store.commit('adminBuildingStore/setImages', this.building.images)
    },
    async updateImages(images, selectedImageIndex) {
      const newImages = await Promise.all(
        images.map(async (image, index) => {
          const form = new FormData()
          form.append('file', image)
          if(selectedImageIndex == null){
            selectedImageIndex = 0;
          }
          form.append('isPrimary', selectedImageIndex === index)
          form.append('orderIndex', 0)

          const { data: newImage } = await buildingService.addResource(this.building.id, form)

          return newImage
        })
      )
      if(this.building.images.length > 0){
        this.deleteImage(this.building.images[0].id);
      }
      this.building.images.push(...newImages)
      this.selectedImageIndex = this.building.images.findIndex(image => image.isPrimary)
      this.$store.commit('adminBuildingStore/setImages', this.building.images)
      this.$toasted.success(this.$i18n.t(`Building images successfully edited!`))
      this.$forceUpdate()
    },
    updateInfos(infos) {
      this.building.infos = infos
    },
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true
        const response = await buildingService.editBuilding(this.building)

        if (response.status === 200) {
          this.$toasted.success(this.$i18n.t(`Building successfully edited!`))
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-building {
  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
    text-transform: uppercase;
  }
  .building-section {
    margin-top: 40px;

    .header {
      border-bottom: 1px solid $dark-gray;
    }
    .section-title {
      @include font-style($montserrat, 'semibold', $font-22);
      color: $dark-blue;
      text-transform: uppercase;
      margin: 0;
    }
    .add-info-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $success;
    }
  }
}
</style>
